/* style.scss */

/* shared color variables -- 
here for foundation too 
(foundation uses global.scss for colors)*/
$custom-blue: #004FB5 !default;
$custom-neonblue: #01B5F0 !default;
$custom-lightblue: #E8F3F5 !default;

$sf-blue: rgba(12,55,99,1);
/*$sf-green: rgba(89,86,105,1);*/
$sf-green: #bdba69;
/*$sf-brown: rgba(61,31,07,1);*/
$sf-brown: #3d1f07;
$custom-red: rgba(12,55,99,1);


$reveal-overlay-background: rgba(0,0,0, .5) !default;

/*@import "vendor/masonry/labs";
@import "vendor/masonry/masonry";*/
@import "vendor/foundation/assets/foundation";
/* @import "../css/vendor/css/foundation.min"; */
@import "webfonts";
//@import "vendor/photoswipe/main";
//@import "vendor/photoswipe/default-skin/default-skin";






/* INDEX */
/* 
-variables
-shared
	- nav
-pages and page overrides
-site overrides 
- mobile
- masonry
*/


/* Variables */

/* 
font-family: 'IM Fell Great Primer', serif;
font-family: 'IM Fell Great Primer SC', serif; this is all caps
font-family: 'Cormorant', serif;
font-family: 'Cormorant SC', serif;  this is all caps*/
/*font-family: 'Roboto', sans-serif;*/

/* shared */

/*#page-container{
	border-top: 2px solid $sf-blue;
}*/

.bold{
	font-weight: bold;
}

.redWarning{
	color: red;
	text-decoration: underline;
	cursor: pointer;
}


body, body p{
	font-family: 'Roboto', sans-serif;
	font-size: 1.25rem;
	font-weight: 400;
	color: #0c3763;
}

.pageTitle{
	/*margin-top: 100px;*/
	display: inline-block;
	width:100%;
}


a, a:visited{
	color: $sf-blue;
}

a:hover{
	text-decoration: underline;
	color: rgba($sf-blue, .75);
}

a.telfont{
	/*font-family: 'Cormorant', serif;*/
	font-family: 'Roboto', sans-serif;
	font-size: 1.25rem;
	color: $sf-blue;
	}

h1{
	text-align:center;
}

h1, h2, h3{
		/*font-family: 'Cormorant', serif;*/
		/*font-family: 'IM Fell Great Primer SC', serif;*/
		/*font-family: 'IM_Fell_Great_Primer_SC', serif;*/
		/*color: $sf-blue;*/
		/* font-size:3rem; */
		/* text-transform: uppercase; */
	}



/*@include breakpoint(medium down) {*/

/*mobile site title*/

#siteTitle{
	
	top:-5px;
	color:$sf-blue;
	font-weight: bold;
	
	#navLogoTop{
	    font-size: 1.125rem;
    text-transform: uppercase;
    /*font-weight: 400;*/
    left: 84px;
    position: relative;
    top: -15px;
	/*@media print, screen and (max-width: 400px){*/
		display: none;
	/*}*/
	}
	#navLogoBottom{
	  position: relative;
    left: -90px;
    font-size: .8rem;
    
    font-weight: 800;
    letter-spacing: .125rem;
    top: 10px;
		@media only screen and (max-width: 350px) {
			font-size: 1.25rem;
			/*padding-top:5px;*/
		}
		/*@media print, screen and (max-width: 400px){*/
		display: none;
	/*}*/
	}
}

#stickyFooter{
	/*font-family: 'IM Fell Great Primer SC', serif;*/
	font-size: 1rem;
	background-color: rgba(0, 0, 0, .25);
	ul{ 
		list-style: none;
		margin-left: 0px;
		li{
		color: #fff;
		text-align: center;
		}
	}
}

.foot-bar{
	border-top: 15px solid $sf-green;
	height: 30px;
}


#globalFooter{

	.cell{
		//display: flex;
	  	align-items: center;
	}

	.foot-copy{
		/*text-align: left;*/
		margin-bottom: 30px;
		a{
			
			color: $sf-brown;
			font-size: 1rem;
			font-weight: 500;
		}
	}

	.biz-hrs{
		font-size: 1rem;
    font-weight: 600;
	}

	.copy-cell{
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.foot-img{
		width: 100px;
		@include breakpoint(medium down) {
		
		}
	}

	.img-cell{
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
	}


	#subFoot{
		/*background-color: $sf-blue;
		color:#fff;*/
	}
	margin-top: 30px;

	/*border-top: 1px solid #e8e8e8;*/
	/*border-bottom: 10px solid $sf-blue;*/
	/*font-family: 'Cormorant SC', serif;*/
	position: relative;
	//top:64px;
	font-size: 1.25rem;
	/*background-color: $custom-lightblue;*/
	/*background-color: rgba(255,198,0,.40);*/
	/*background-image: url(../img/gold3med.png);*/
	/*background: linear-gradient(to bottom, white 0%, black 150%), url(../img/gold4.jpg);*/
	background-repeat: repeat-x;
	background-blend-mode: screen;
	/*margin-top: 30px;*/
	padding-top: 50px;
	padding-bottom: 50px;
	ul{ 
		list-style: none;
		margin-left: 0px;
		
		li, li p{
			/*font-family: 'Cormorant SC', serif;*/
			color: $sf-blue;;
			text-align: center;
			/* font-size:1.5rem; */
			@media screen and #{breakpoint(small down)} {
 				font-size: 1.125rem; 
			}
			&.heading{
				/*font-family: 'Crimson Text', serif;*/
    			text-transform: uppercase;
    
				&:hover{text-decoration: underline; cursor: pointer;}
				font-size: 1.25rem;
			}
			i{
				/*font-size:2rem;*/
			}
			.fa-1x{
				font-size: 1rem;
			}
		}
	}
}

#sitemapFooter{
	margin-top: 30px;
}

#devCredit{
	font-size: 1rem;
}

/* nav */



.top-bar-right{
	position: absolute;
	right:10px;
	a{
		display: inline-block;
	}
	
	@media print, screen and (max-width: 908px){
		position: relative;
		text-align: center;	
		a{
			
		}
	}
	
	
	
}



#navLogo{
	a{
		&:hover{
			text-decoration: none;
		}
	}
	position: absolute;
	
	z-index: 1;
	/* background-size: 50px;
	background-repeat: no-repeat; */
	top:10px;
	#navLogoLeft{
		/*display: block;*/
		position: absolute;
		/*float: left;*/
		/*margin-left: -80px;*/
		font-size: 2.75rem;
		margin-left:20px;
		top:-20px;
		width:35px;


	}

	#navLogoRight{
		color: $sf-blue;
		left:70px;
		position: relative;
		text-align:center;
		top:-2px;
		font-family: "Times";
		@media print, screen and (max-width: 1024px){
			/*display: none;*/
		}
	}
	
	#navLogoTop{
		color: $sf-blue;
		font-family: 'Roboto', sans-serif;
		font-size: 1.125rem;
		text-transform: uppercase;
		font-weight: 300;
		letter-spacing: 1px;
		span.bold{
			font-weight: 800;
		}
		
	}
	#navLogoBottom{
		color: $sf-blue;
		position: relative;
		top:-14px;
		font-size: .8rem;
		text-transform: uppercase;
		font-weight: 100;
		letter-spacing: .125rem;
		font-family: 'Roboto', sans-serif;
	}
}



#navBg{
	background-color: #fff;
	border-bottom: 1px solid $sf-blue;
}

.top-bar{
	font-family: 'Roboto', sans-serif;
	text-transform: uppercase;
	font-size: 1.25rem;
	ul li a:hover{
		color: rgba($sf-blue, .5);
	}
	
	ul li.active li a:hover{
		color: rgba($sf-blue, .5);
	}

	ul li.active li.active a:hover{
		color: #fff;
	}

	ul li.active li a{
		color: $sf-blue;
	}


	ul li.active > a:first-child{
		color: #fff;
	}

	ul li.active > a:first-child{
		background-color: $sf-blue;
	}

	.fa-1x{
		font-size: 1rem;
	}

	.is-dropdown-submenu{
		li.active{}	
	}

	.is-dropdown-submenu.contact-drop{
		min-width: 230px;
	}

	.is-dropdown-submenu.about-drop{
		min-width: 160px;
	}

	.is-dropdown-submenu.charters-drop{
		min-width: 420px;
	}

	@include breakpoint(medium) {
		.dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent.active > a::after {
			border-top-color: #fff;	
		}


		.dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent.active.is-active > a::after {
			border-top-color: $sf-blue;	
		}

		
	}

	.menu .active > a {
		width:100%;
	}

		
}

/*fix blue hover*/
@media print, screen and (min-width: 56.8125em){
.dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a {
	color: $sf-blue;
	&:hover{
		color: rgba($sf-blue, .75);
	}
}
}

.dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent.active > a{
	color: #fff;
}

/*fix blue mobile arrow*/
/*@media screen and (max-width: 63.99875em){*/
.drilldown .is-drilldown-submenu-parent > a::after{
	border-left-color: $sf-blue;
	}
.drilldown .js-drilldown-back > a::before{
	border-right-color: $sf-blue;
}
/*}*/




/* per zurb preventing FOUC */
.no-js {
  @include breakpoint(small only) {
    .top-bar {
      display: none;
    }
  }

  @include breakpoint(medium) {
    .title-bar-right {
      display: none;

    }
  }
}



/*nav mobile*/

.title-bar-right{
	height: 58px;
}


@include breakpoint(medium down) {
	#navLogo{	
		/*left:20px;
		width: 50px;
		padding-top:5px;*/
	}

	#siteTitle{
		/*top:-20px;*/
	}

	.menu-icon {   
	    left: -30px;
	    width: 30px;
	    z-index: 100;
	    top:10px;
	}
	.menu-icon::after{
		height: 3px;
	}
	.top-bar{
		width: 100%;
	}
	.is-drilldown, .menu.drilldown{
		width: 100%;
		li{
			width: 100%;
		}
	}

	.drilldown .is-drilldown-submenu-parent > a::after{
		right: 0rem;
	}

	.active.is-drilldown-submenu-parent > a::after{
		right: 1rem;
		border-left-color: #fff !important;
	}

}

/*sticky fix for mobile*/
.sticky.asaStuck {
    position: fixed;
    z-index: 5;
    width: 100%;
    top:0px !important;

    border-top: 5px solid $sf-blue;
}

/* Page overrides */

main{
	position: relative;
	/*top: -16px;*/
	/*top:-105px;*/
	z-index: -1;
	/*padding-top: 50px;*/
	top:64px;

}

.paddingTopMain{
	margin-top: 100px;
}

/* Home */




#homeFold{
	/*padding-top:50px;*/

	h3{
		/*@include breakpoint(908px) {*/
    		font-size:2.25rem
  		/*}*/
		
	}
}

a.imageBox{
	&:hover{
		opacity: .5;
	}
}


.bannerText{
	text-align: center;
	.button{
		/*background-color: rgba(216, 216, 216, 0.8);
	    border: 1px solid #fff;
	    color: #590000;*/
	    color: #sf-blue;
	    /*font-weight: bold;*/
	    font-weight: 200;
	    font-size: 1.25rem;
	    margin-top: 20px;
	    font-family: 'Roboto', sans-serif;
	    &:hover{
	    	/*background-color: #fff;*/
	    }
	    /*border-radius: 8px;*/
	    padding: 15px 20px;

	}
	span{
		position: absolute;
		top:-3000px;
	}
	/*h2{
		font-size: 1.75rem;
		font-weight: 500;
	}*/

}



#homeButtons{
	padding-top: 50px;
	padding-bottom: 30px;
	@include breakpoint(small only) {
		/*margin-top: 100px;*/
	}
}

/*email button*/
.email-button{
		background-color: rgba(12,55,99,1);
		border-radius: 10px;
	    font-size: 1.25rem;
	    font-weight: 400;
	    color: $sf-green;
	    text-transform: uppercase;
        /*padding: 10px 30px;*/
        padding: 0.85em 1em;
		position: relative;
        top: 20px;
        transition: all ease-out .35s;
		transition: all ease-in .25s;
		&:hover{
			background-color: rgba(12,55,99,.75);
			color: #fff !important;
			text-decoration: none !important;
		}
	}


/*about*/
h2.aboutHead{


		/*@include breakpoint(small only) {
			text-align: left;
			padding-left: 10px;
		}*/
}

.about-h1{
	color: #fff;
	background-color: $sf-blue;
	width: 100%;
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	/*margin-bottom: 30px;*/

}



.about-slide{
	
	margin-top: 40px;
	/*border-bottom: 15px solid $sf-green;*/
	padding-bottom: 30px;
	.button{
		background-color: rgba(12,55,99,1);
		border-radius: 10px;
	    font-size: 1.25rem;
	    font-weight: 400;
	    color: $sf-green;
	    text-transform: uppercase;
	}
	p.sub{
		font-size: 1rem;
		text-align: center;
	}
	p {
		font-size: 1rem;
		text-align: left;
		font-weight: 500;
	}
	h2{
		text-transform: uppercase;
		border-bottom: 5px solid $sf-green;	
		font-weight: 500;
		font-size: 2rem;
		text-align: left;
		@include breakpoint(small only) {
			font-size: 1.5rem;
		}

	}
	
}

/*faq & Contact*/
.faq-h1, .contact-h1, .blog-h1, .bloglist-h1{
	color: #fff;
	background-color: $sf-blue;
	width: 100%;
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	/*margin-bottom: 30px;*/
    top: 31px;
    position: relative;
    padding-bottom: 10px;

}
ul#faq{
	
	margin-right: 1.25rem;

	li{
		list-style:none;
		text-align: left;
		padding:10px;
	}
	
	li:nth-child(even){
		background-color: rgba(61,31,07,.125);
	}

	h4{
		text-align: left;
		font-weight: bold;
	}
	p{
		font-weight: 400;
	}

	a{
		text-decoration: underline;
	}
}

/*covid*/
.covid-policy{
	padding-top: 20px;
	//padding-bottom: 40px !important;
	/*border-bottom: 15px solid $sf-green;*/
	position: relative;
	/*background: url('/img/shared/LEAF_GRAPHIC.png');*/
}
.covid-banner{
	background-color: $sf-green;
	border-bottom: 1px solid $sf-blue;
	text-align: center;
	font-size: .85rem;
	a{
		color: #fff;
	}
}



.leaf{
	position: absolute;
	bottom: -40px;
	width: 60px;
	left: 45%;
	/*@include breakpoint(small only) {
		left: 45%; 
	}
	@include breakpoint(medium only) {
		left: 35%;	
	}*/
}
.covid-policy{
	.leaf{
		display: none;
	}
}


.covid-policy2{
	//padding-top: 40px !important;
li::marker{
	color: $sf-green;
}
}

h2.covidHead{
	color: $sf-blue !important;
	@include breakpoint(medium) {
		color: #fff !important;
		font-size: 2.75rem !important;
	}

	/*@include breakpoint(small only) {
		text-align: left;
		padding-left: 10px;
	}*/
}

.covid-slide{

	margin-top: 40px;
	/*border-bottom: 15px solid $sf-green;*/
	padding-bottom: 30px;
	.button{
		background-color: rgba(12,55,99,1);
		border-radius: 10px;
	    font-size: 1.25rem;
	    font-weight: 400;
	    color: $sf-green;
	    text-transform: uppercase;

	}
	p.sub{
		font-size: 1rem;
		text-align: center;
	}
	p, ul li{
		font-size: 1rem;
		text-align: left;
		font-weight: 500;
	}
	h2{
		text-transform: uppercase;
		border-bottom: 5px solid $sf-green;	
		font-weight: 500;
		font-size: 2rem;
		text-align: left;
		@include breakpoint(small only) {
			font-size: 1.5rem;
		}

	}
	
}


// visibility: 

.visit-1{
	
	

	margin-top: 40px;
	
	padding-bottom: 30px;
	.button{
		background-color: rgba(12,55,99,1);
		border-radius: 10px;
	    font-size: 1.25rem;
	    font-weight: 400;
	    color: $sf-green;
	    text-transform: uppercase;
	}
	p.sub{
		font-size: 1rem;
		text-align: center;
	}
	p {
		font-size: 1rem;
		/*text-align: left;*/
		font-weight: 500;
	}
	p#hrs{
		font-size: 1.5rem;
		/*text-align: left;*/
		font-weight: 500;
		margin-bottom: 0px;
		color: $sf-brown;
	}
	h2{
		text-transform: uppercase;
		/*border-bottom: 5px solid $sf-green;	*/
		font-weight: 500;
		font-size: 2rem;
		/*text-align: left;*/
		@include breakpoint(small only) {
			font-size: 1.5rem;
		}

	}

	iframe{
		border: 8px solid $sf-blue !important;
		width: 90%;
		height: 400px;
	}
	#biz-img-cell{
		padding-right: 20px;
	}
	#biz-hrs{
		display: flex;
		justify-content: center;
		align-items: center;

		img{
			position: relative;
			float: left;
			margin-right: 20px;
			top: -60px;
		}
	}
}
#selection{
	text-align: center;
}

.visit-2{
	border-top: 15px solid $sf-green;
	/*border-bottom: 15px solid $sf-green;*/
	/*height:90vh;*/
	background: url('/img/visit/VISITPAGE02sm.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	width:100%;
	background-position-y: center;
	background-position-x: center;
	position: relative;
	/*display: flex;*/
  	align-items: center;

	h2{
		
		font-weight: 400;
		font-size: 3rem;
		line-height: 4.25rem;
		text-align: left;
		color: #fff;
		padding-bottom: 20px;
		//padding-left: 15px;
	}
	.button{
		background-color: $sf-green;
		border-radius: 10px;
	    font-size: 1.25rem;
	    font-weight: 500;
	    color: $sf-blue;
	    text-transform: uppercase;
	    float: left;
	    margin-left:20px;

	}
	li{
		list-style:none;
	}
}


/*THIS IS IN 2 PLACES*/
h2.visitHead{

	color: #fff !important;
	font-size: 2rem;

	/*@include breakpoint(small only) {
		text-align: left;
		padding-left: 10px;
	}*/
	@include breakpoint(medium) {
		
		font-size: 2.5rem !important;
	}
}

div#productBoxes{
  /*margin-top: 20px;*/
}
div#productBoxes .card{
  height: 355px;
}
div#productBoxes h2{
  margin-top: 20px;
  font-size:2rem;
  text-align: center;
  text-transform: uppercase;
  font-family: Zapatista, serif;
  /*height: 355px;*/
}
div#productBoxes h2 a{
  color:#fff;
  text-transform: capitalize;
  letter-spacing: 2px;

  }

div#productBoxes h2 a:hover{
  text-decoration: underline;
}

div#productBoxes h3{
  text-align: center;
}
div#productBoxes ul{
  text-align: center;
  margin-left: 0px;
}
div#productBoxes ul li{
  text-decoration: none;
  list-style: none;
}
div#productBoxes ul li a, div#productBoxes a{
  color: #fff;
  font-size: 2.25rem;
  font-weight: 500;
  
}
div#productBoxes ul li a:hover{
  text-decoration: underline;
}

div#productBoxes .card{
  border: solid 2px #fff;
 /* border-top: 0px !important;*/
  /*border-bottom: 0px;*/
  margin-bottom:0px !important
}
a div#productBoxes .card:hover{
	background-color: rgba(12,55,91,.5);
	transition: all ease-out .35s;
	transition: all ease-in .25s;
}

div#productBoxes .cell.card{
  
    background-blend-mode: darken;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: center;
  position: relative; 
  color: #fff;
  background:none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  @include breakpoint(small only) {
	height:220px;
  }	
}

a.boxy{	
	display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

a.boxy:hover{
	background-color: rgba(12,55,91,.5);
	transition: all ease-out .35s;
	transition: all ease-in .25s;
}

/*page banner*/
div#pageBanner{
	border-bottom: 15px solid #bdba69;
	@include breakpoint(large down) {
	/*	background-size: cover;*/
	}
	/*_______now inline______*/

	/*background: linear-gradient(to bottom,black 0%,white 100%), url(../img/shared/CopyofAmyPoturnicki.jpg);*/
	/*background-blend-mode: screen;
	background-size: cover;
	background-repeat: no-repeat;
	width:100%;
	background-position-y: center;
	background-position-x: center;
	position: relative;
	top:72px;*/
	height:100vh;
	
	.bannerText{
		position: relative;
		top:10%;
		@include breakpoint(small only) {
			top:15%;
		}
		color:$sf-blue;
	    width: 100%;
	    h2{
	    	color:$sf-blue;
	    }

	    h2.aboutText{
			color:#fff !important;		
			margin-top: 15%;	
		}

	}
	
	
	
	

	h1{
		color:#fff;
	    /*font-weight: 900;*/
	    font-family: 'Roboto', sans-serif;
	    font-weight: 200;
	    @include breakpoint(small only) {
	    	font-size:2rem;
	    	img{
	    		padding-bottom:10px;
	    	}
	    }
	}
	h2{
		font-family: 'Roboto', sans-serif;
		color:#fff;
		font-weight: 500;
		font-size: 1.75rem;
		@include breakpoint(small only) {
	    	/*font-size:1rem;*/
	    }
	}
	h3{
		font-weight: 400;
		font-size: 1.5rem;
	}
}

/*home slide 2*/
.slide2{
	
	margin-top: 40px;
	
	padding-bottom: 30px;
	.button{
		background-color: rgba(12,55,99,1);
		border-radius: 10px;
	    font-size: 1.25rem;
	    font-weight: 400;
	    color: $sf-green;
	    text-transform: uppercase;
	    transition: all ease-out .35s;
		transition: all ease-in .25s;
		&:hover{
			background-color: rgba(12,55,99,.75);
			color: #fff !important;
			text-decoration: none !important;
		}
	}
	p.sub{
		font-size: 1rem;
		text-align: center;
	}
	p{
		font-size: 1rem;
		text-align: left;
		font-weight: 500;
	}
	h2{
		text-transform: uppercase;
		border-bottom: 5px solid $sf-green;	
		font-weight: 500;
		font-size: 2rem;
		text-align: left;
		@include breakpoint(small only) {
			font-size: 1.5rem;
		}

	}

	
}

.slide3{
	border-top: 15px solid $sf-green;
	border-bottom: 15px solid $sf-green;
	height:90vh;
	background: url('/img/home/HOMEPAGE02sm.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	width:100%;
	background-position-y: center;
	background-position-x: center;
	position: relative;
	display: flex;
  	align-items: center;
  	justify-content: center;

	h2{
		
		font-weight: 400;
		font-size: 3rem;
		line-height: 4.25rem;
		/*text-align: left;*/
		color: #fff;
		padding-bottom: 20px;
		//padding-left: 15px;
	}
	.button{
		background-color: #fff;
		border-radius: 10px;
	    font-size: 1.25rem;
	    font-weight: 500;
	    color: $sf-green;
	    text-transform: uppercase;
	    float: left;
	    margin-left:20px;
	    transition: all ease-out .35s;
		transition: all ease-in .25s;
		&:hover{
			background-color: rgba(12,55,99,1);
			color: #fff !important;
			text-decoration: none !important;
		}


	}
	.cell{
		@include breakpoint(medium) {
			/*margin-left: 80px;*/
		}
	}
}

.slide4{
	border-top: 15px solid $sf-green;
	height: 90vh;
	background: url('/img/home/HOMEPAGE03sm.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	width:100%;
	background-position-y: center;
	background-position-x: center;
	position: relative;
	/*display: flex;
  	align-items: center;*/
	h2{
		
		font-weight: 400;
		font-size: 4rem;
		line-height: 4.25rem;
		text-align: left;
		color: $sf-brown;
		padding-top: 10px;
		padding-bottom: 20px;
		padding-left: 15px;
	}
	.button{
		background-color: #fff;
		border-radius: 10px;
	    font-size: 1.25rem;
	    font-weight: 500;
	    color: $sf-brown;
	    text-transform: uppercase;
	    /*float: left;*/
	    /*margin-left:20px;*/
	    position: absolute;
	    left: 30px;
	    bottom: 30px;
	    transition: all ease-out .35s;
		transition: all ease-in .25s;
		&:hover{
			background-color: rgba(12,55,99,1);
			color: #fff !important;
			text-decoration: none !important;
		}
	    @include breakpoint(medium) {
	   		position: relative;
	   		float: left;
	   		bottom: 0px;
	    }
	    
	}
	.cell{
		@include breakpoint(medium) {
			padding-left: 80px;
		}
	}
}

.slide5{
	border-bottom: 15px solid $sf-green;
	height: 90vh;
	background: url('/img/home/HOMEPAGE04sm1.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	width:100%;
	background-position-y: center;
	background-position-x: center;
	position: relative;
	display: flex;
  	align-items: flex-end;

	h2{
		
		font-weight: 400;
		font-size: 4rem;
		line-height: 4.25rem;
		text-align: left;
		color: #fff;
		padding-top: 10px;
		padding-bottom: 20px;
		padding-left: 15px;
	}
	p{
		color: #fff;
		text-align: left;
		font-weight: 500;
	}
	.button{
		background-color: #fff;
		border-radius: 10px;
	    font-size: 1.25rem;
	    font-weight: 500;
	    color: $sf-brown;
	    text-transform: uppercase;
	    float: left;
	    transition: all ease-out .35s;
		transition: all ease-in .25s;
		&:hover{
			background-color: rgba(12,55,99,1);
			color: #fff !important;
			text-decoration: none !important;
		}
	    /*margin-left:20px;*/
	    /*position: absolute;
	    left: 30px;
	    bottom: 30px;*/
	}
}

.slide6{
	/*border-bottom: 15px solid $sf-green;*/
	/*padding-bottom: 30px;*/
	padding-top: 20px;
	h2{
		
		font-weight: 400;
		font-size: 4rem;
		line-height: 4.25rem;
		text-align: left;
		color: $sf-blue;
		padding-top: 10px;
		padding-bottom: 20px;
		padding-left: 15px;
	}
	p{
		color: $sf-blue;
		text-align: left;
		font-weight: 500;
	}
	input[type=text]{
    /*-webkit-border-radius: 20px;
    -moz-border-radius: 20px;*/
     border-radius: 15px;
     height: 60px;
     font-size: 20px;
     /*border: 1px solid #2d9fd9;
     color: #a0d18c;
     width: 250px;
     height: 30px;
     padding-left: 10px;*/

    }
    
	/*input[type=text]:focus {
	     outline: none;
	     border: 1px solid #a0d18c;
	     color: #2d9fd9;
	}*/
	#join{
		width: 80%;
		margin:0 auto;	
		@include breakpoint(medium) {
     		width: 50%;
     	
     }
	}
}


/*rates*/
ul#rates-tabs{ 
	h3 {
		text-align: center;
		font-size: 1rem !important;

	}


}

#rates-tables{
	font-size: 1rem;
	table {
		text-align: center
	}

	h2{
		text-align: center;
	}
	h4 {font-weight: bold;}
	p{
		line-height: 1rem !important;
		font-size: 1.125rem;
	}
	.tabs-panel{
		padding:1rem 0rem;
	}
}

.tabs-title {
	float:none !important;
	display:inline-block;
	vertical-align: middle;

		
		width:16.6%;
	

}

.tabs {
	text-align:center;
}

.tabs-title > a{
	padding: .5rem;

}

/*masonry*/
$masonry-css-column-gap: $global-margin;

.masonry-css {
  column-count: 2;
  column-gap: $masonry-css-column-gap;

 /* @include breakpoint(300px) {
    column-count: 2;
  }*/

  @include breakpoint(600px) {
    column-count: 3;
  }

  @include breakpoint(800px) {
    column-count: 3;
  }

  @include breakpoint(1100px) {
    column-count: 3;
  }
}

.masonry-css-item {
  break-inside: avoid;
  margin-bottom: $masonry-css-column-gap;
}

/*gallery*/
.my-gallery, #portfolioList{
	margin-top: 50px;
}


#ourSelection{
	h2{color: #fff;}
}

#shop{
	
	margin-top: 50px;

	h1, h2, th{
		font-family: 'Roboto', sans-serif;
	}
	
	h2{
		//margin-top: 30px;
		@include breakpoint(small only) {
			padding-left: 10px;
		}

	}

	th{
		font-weight: 400;
	}

	td, h3{
		font-family: 'Roboto', sans-serif;
		font-size:1.5rem;
	}

	td{
		/*font-weight: 100;*/
	}

.shop-head{
	
	margin-top: 40px;
	
	padding-bottom: 30px;
	.button{
		background-color: rgba(12,55,99,1);
		border-radius: 10px;
	    font-size: 1.25rem;
	    font-weight: 400;
	    color: $sf-green;
	    text-transform: uppercase;
	    transition: all ease-out .35s;
		transition: all ease-in .25s;
		&:hover{
			background-color: rgba(12,55,99,.75);
			color: #fff !important;
			text-decoration: none !important;
		}
	}
	p.sub{
		font-size: 1rem;
		text-align: center;
	}
	p{
		font-size: 1rem;
		text-align: left;
		font-weight: 500;
	}
	h2{
		text-transform: uppercase;
		border-bottom: 5px solid $sf-green;	
		font-weight: 500;
		font-size: 2rem;
		text-align: left;
		@include breakpoint(small only) {
			font-size: 1.5rem;
		}

	}

	
}
}
h2.shopHead, h2.visitHead{

	color: #0c3763 !important;
	font-size: 2rem;


	@include breakpoint(small only) {
		text-align: left;
		padding-left: 10px;
	}
	@include breakpoint(medium) {
		
		font-size: 2.5rem !important;
		font-weight: 300 !important;
	}
}

h1.shopHead{
	
}

h1.shopHead, h1.visitHead{
	padding-top: 25px;

	color: #fff !important;
	font-size: 3rem !important;
	font-weight: 700 !important;

	@include breakpoint(small only) {
		/*text-align: left;
		padding-left: 10px;*/
	}
	@include breakpoint(medium) {
		
		/*font-size: 2.75rem !important;*/
	}
}

#step3-button, #visit-option-button, #pickup-option-button, .pickup-option-button{
	background-color: rgba(12,55,99,1);
		border-radius: 10px;
	    font-size: 1.25rem;
	    font-weight: 400;
	    color: $sf-green !important;
	    text-transform: uppercase;
        /*padding: 10px 30px;*/
        padding: 0.85em 1em;
		position: relative;
        top: 10px;
        bottom: 20px;
        margin-bottom: 30px;
        transition: all ease-out .35s;
		transition: all ease-in .25s;
		&:hover{
			background-color: rgba(12,55,99,.75);
			color: #fff !important;
			text-decoration: none !important;
		}

}

#steps3{
		h3{
			padding-top: 20px;
			font-weight: 200;
		}
		ol li::marker{
			font-weight: 200;
		}
		ul li{
			font-weight: 300;
		}

	@include breakpoint(small only) {
		h2{
			font-size: 2rem !important;
		}
	}	

	@include breakpoint(medium) {
		ol li::marker{
		font-size: 1.85rem;
		font-weight: 300;
		}

	}
}

#pickup-option-reveal{
	a.text-link{ 
	text-decoration: underline;
	color: $sf-green;

 }
}

.reveal #steps3{
		padding:2rem;
	}

#not21{
	font-size: 1rem;
}

#home .reveal-overlay{
	background-color: rgba(0,0,0,.75);

}

html.is-reveal-open.zf-has-scroll{
	overflow:visible;
}

div#twentyonemodal{
	color:#fff;
	width: 100%;
	height: 100%;
	max-width: 100% !important;
	position: relative;
	top:0px !important;
	text-align: center;
	background-color: #000;
	@include breakpoint(small only) {
		background-color: rgba(0, 0, 0, 0.5);
	}
	background-color: #000;
	h1{font-family: 'Roboto', sans-serif;}
	p, span, a{
		color:#fff;
		font-family: 'Roboto', sans-serif;
	}

}

div#wholesaleModal{
	/*color:#fff;*/
	text-align: center;
	/*background-color: rgba(0, 0, 0, 0.5);*/
	h1{font-family: 'Roboto', sans-serif;}
	p, a{
		/*color:#fff;*/
		font-family: 'Roboto', sans-serif;
		font-size: 1rem;
	}
}


#wholeButton, #sched{
	font-size: 1.25rem;
	font-family: 'Roboto', sans-serif;
	font-weight: 200;
}

/*Blog*/
.blog-post{
	h1{

		margin-top: 30px;
	}
	border-bottom: 15px solid $sf-blue;
}